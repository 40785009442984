import React from "react";
// styled
import { BasicNavLink, Container, PageTitle, Section } from "components/Common/common.styled";
import Logo from "components/Logo/Logo";

const ErrorPermissionPage = () => {
  return (
    <Section>
      <Container>
        <Logo className={"header"} />
        <PageTitle>You do not have access to this page</PageTitle>
        <BasicNavLink to="/" >Back to homepage</BasicNavLink>
      </Container>
    </Section>
  );
};

export default ErrorPermissionPage;
