import React from "react";
// hooks
import useAuth from "hooks/useAuth";
// components
import OfficeNavAdmin from "./OfficeNavAdmin";
import OfficeNavSuperadmin from "./OfficeNavSuperadmin";

const OfficeNav = ({ setIsBurgerMenuOpen, isSmallView }) => {
  const { isSuperAdmin, isAdmin} = useAuth();

  return (
    <>
      {isAdmin && ( <OfficeNavAdmin setIsBurgerMenuOpen={setIsBurgerMenuOpen} isSmallView={isSmallView} /> )}
      {isSuperAdmin && ( <OfficeNavSuperadmin setIsBurgerMenuOpen={setIsBurgerMenuOpen} isSmallView={isSmallView} /> )}
    </>
  );
};

export default OfficeNav;
