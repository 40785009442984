const { createNews } = require("./createNews");
const { getNews } = require("./getNews");
const { updateNewsById } = require("./updateNewsById");
const { deleteNewsById } = require("./deleteNewsById");

module.exports = {
  createNews,
  getNews,
  updateNewsById,
  deleteNewsById,
};
