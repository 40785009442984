import styled from "styled-components";
import { Link } from "react-router-dom";
// common
import { maxDevice } from "common/breakpoints";

export const LogoLink = styled(Link)`
  display: flex;
  width: 122px;
  height: 20px;

  gap: 1.2px;

  & svg {
    fill: #fff;
    height: auto;
    @extend %transition--20;
  }

  &.header {
    $animation-delay: 20ms;
    $animation-duration: 800ms;
    $animation-delay-1: 2000ms;
    $animation-delay-2: $animation-delay-1 + $animation-duration + $animation-delay;
    $animation-delay-3: $animation-delay-2 + $animation-duration + $animation-delay;
    $animation-delay-back: $animation-delay-3 + $animation-duration + $animation-delay;

    & .logo__icon--1 {
      animation: zzz $animation-duration $animation-delay-1 ease-in 1 forwards,
        zzz $animation-duration $animation-delay-back ease-in 1 forwards reverse;
    }
    & .logo__icon--2 {
      animation: zzz $animation-duration $animation-delay-2 ease-in 1 forwards,
        zzz $animation-duration $animation-delay-back ease-in 1 forwards reverse;
    }
    & .logo__icon--3 {
      animation: zzz $animation-duration $animation-delay-3 ease-in 1 forwards,
        zzz $animation-duration $animation-delay-back ease-in 1 forwards reverse;
    }
    & .logo__icon--4 {
      transform: translateY(10%);
    }
  }

  @media ${maxDevice.mobileSM} {
    &.header {
      width: 104px;
      height: 17px;
    }
  }

  @keyframes zzz {
    0% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(-180deg);
    }
  }
`;
