import React from "react";
// helpers
import { getLocalDateWithDayOfWeek } from "helpers";
// styled
import { TodayBox } from "./Today.styled";

const Today = () => {
  return (
    <TodayBox>
      {getLocalDateWithDayOfWeek(new Date(), "long")?.en}
    </TodayBox>
  );
};

export default Today;
