import styled from "styled-components";
import { Link } from "react-router-dom";
// common
import { maxDevice, minDevice } from "common/breakpoints";

export const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  min-height: 32px;
  padding: 4px 12px;

  // color: inherit;
  font-weight: 500;
  font-size: inherit;
  line-height: 1;

  // background-color: transparent;
  border: none;
  border-radius: var(--border-radius--4);
  transition: var(--transition--100);

  & svg {
    flex: 0 0 16px;
    width: 16px;
    height: auto;

    fill: currentColor;
    transform: scale(1.01);
    transition: var(--transition--100);
  }

  &.short {
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    padding: 0;
  }
  
  &.btn-close {
    & svg {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;

      transform: rotate(45deg);
    }
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: var(--box-shadow--active);
  }
  
  &:focus-visible { outline: 1px solid var(--color--accent, #f19e1d); }
`;

export const BtnWrap = styled.div`
  display: flex;
  align-items: center;

  border-radius: var(--border-radius--4);
  overflow: hidden;
`;

// ===== COLOR button start =====
export const BtnAccent = styled(Btn)`
  color: var(--color--accent, #f19e1d);
   background-color: transparent;

  &:hover {
    color: #fff;
    background-color: var(--color--accent, #f19e1d);
  }
`;
export const BtnAccentBorder = styled(BtnAccent)`
  border: 1px solid currentColor;

  &:hover { border-color: var(--color--accent, #f19e1d); }
`;
export const BtnAccentSolid = styled(Btn)`
  color: #fff;
  background-color: var(--color--accent, #f19e1d);
`;

export const BtnPrimary = styled(Btn)`
  color: var(--color--primary, #414141);
   background-color: transparent;

  &:hover {
    color: #fff;
    background-color: var(--color--primary, #414141);
  }
`;
export const BtnPrimaryBorder = styled(BtnPrimary)`
  border: 1px solid currentColor;

  &:hover { border-color: var(--color--primary, #414141); }
`;
export const BtnPrimarySolid = styled(Btn)`
  color: #fff;
  background-color: var(--color--primary, #414141);
`;

export const BtnLight = styled(Btn)`
  color: var(--color--bg, #f1f1f1);
   background-color: transparent;

  &:hover {
    color: var(--color--primary, #414141);
    background-color: var(--color--bg, #f1f1f1);
  }
`;
export const BtnLightBorder = styled(BtnLight)`
  border: 1px solid var(--color--bg, #f1f1f1);
`;
export const BtnLightSolid = styled(Btn)`
  color: var(--color--primary, #414141);
  background-color: var(--color--bg, #f1f1f1);
`;

export const BtnWhiteSolid = styled(Btn)`
  color: var(--color--primary, #414141);
  background-color: #fff;
`;

export const BtnGreen = styled(Btn)`
  color: var(--color--green, #06a561);
  background-color: transparent;

  &:hover {
    color: #fff;
    background-color: var(--color--green, #06a561);
  }
`;
export const BtnGreenBorder = styled(BtnGreen)`
  border: 1px solid currentColor;
  &:hover { border-color: var(--color--green, #06a561); } 
`;
export const BtnGreenSolid = styled(Btn)`
  color: #fff;
  background-color: var(--color--green, #06a561);
`;

export const BtnRed = styled(Btn)`
  color: var(--color--red, #a51806);
  background-color: transparent;

  &:hover {
    color: #fff;
    background-color: var(--color--red, #a51806);
  }
`;
export const BtnRedBorder = styled(BtnRed)`
  border: 1px solid currentColor;
  &:hover { border-color: var(--color--red, #a51806); } 
`;
export const BtnRedSolid = styled(Btn)`
  color: #fff;
  background-color: var(--color--red, #a51806);
`;

// ===== COLOR button end =====
// ===== ACTION button =====
export const BtnRefresh = styled(BtnLightSolid)`
  &:hover svg { transform: rotate(-30deg); }
`;
// ===== ACTION button =====


// ===== OLD VERSION =====
export const BasicBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 4px;
  width; 100%;
  // max-width: 160px;
  height: 36px;
  min-height: 36px;
  padding: 0 12px;

  color: inherit;
  font-size: inherit;
  line-height: normal;

  border-radius: 4px;
  border: 1px solid transparent;
  transition: var(--transition--100);

  @media ${minDevice.tablet} {
    min-width: 120px;
  }

  & svg {
    fill: currentColor;
    transform: scale(1.01);
    transition: var(--transition--100);
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: var(--box-shadow--active);
  }
`;
export const BasicBtnAccent = styled(BasicBtn)`
  color: #fff;
  background-color: var(--color--accent, #f19e1d);
`;
export const BasicBtnLight = styled(BasicBtn)`
  color: var(--color--primary, #414141);
  background-color: var(--color--bg, #f1f1f1);
`;
export const BasicBtnLightBorder = styled(BasicBtn)`
  color: var(--color--primary, #414141);
  background-color: var(--color--bg, #f1f1f1);
  border-color: var(--color--accent, #f19e1d);
`;
export const BasicBtnWhite = styled(BasicBtn)`
  color: var(--color--primary, #414141);
  background-color: #fff;
  border-color: var(--color--accent, #f19e1d);
`;

export const LinkBasic = styled(Link)`
  ${BasicBtn}
  flex-shrink: 0;
  min-width: 100px;
  min-height: 48px;

  color: inherit;
  font-size: 16px;
  line-height: 1.12;

  border-radius: var(--border-radius, 8px);
  border: none;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
`;
export const LinkBasicYellow = styled(LinkBasic)`
  background-color: var(--color--accent2, #ffbe20);

  &:hover,
  &:focus {
    background-color: #ffce52;
  }
`;

export const BtnEditTag = styled(BasicBtnAccent)`
  gap: 8px;

  & svg {
    flex: 0 0 16px;
    width: 16px;
    height: auto;
  }

  @media ${maxDevice.mobileL} {
    min-width: 80px;
  }
`;
export const BtnSaveTag = styled(BasicBtnAccent)`
  gap: 8px;

  & svg {
    flex: 0 0 16px;
    width: 16px;
    height: auto;
  }

  @media ${maxDevice.mobileL} {
    min-width: 80px;
  }
`;
export const BtnCancelTag = styled(BasicBtnWhite)`
  gap: 8px;

  & svg {
    flex: 0 0 16px;
    width: 16px;
    height: auto;
  }

  @media ${maxDevice.mobileL} {
    min-width: 80px;
  }
`;
export const BtnDeleteTag = styled(BasicBtnWhite)`
  gap: 8px;

  & svg {
    flex: 0 0 16px;
    width: 16px;
    height: auto;
  }

  &.accent {
    color: #fff;
    background-color: var(--color--accent, #f19e1d);
  }

  @media ${maxDevice.mobileL} {
    min-width: 80px;
  }
`;
