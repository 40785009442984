import React from "react";
// components
import Logo from "components/Logo/Logo";
import Today from "components/Today/Today";
import BurgerMenuBtn from "components/BurgerMenu/BurgerMenuBtn";
// styled
import { HeaderPrivateTag, HeaderPrivateContainer, } from "./HeaderPrivate.styled";

const HeaderPrivate = ({ isSmallView, isBurgerMenuOpen, setIsBurgerMenuOpen }) => {
  return (
    <HeaderPrivateTag>
      <HeaderPrivateContainer>
        {isSmallView && <Logo className="header" />}
        {!isSmallView && <Today />}
        {isSmallView && <BurgerMenuBtn isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />}
      </HeaderPrivateContainer>
    </HeaderPrivateTag>
  );
};

export default HeaderPrivate;
