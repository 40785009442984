import { createSlice } from "@reduxjs/toolkit";
import { createFaq, deleteFaqById, getFaqs, updateFaqById } from "./operations";
import { isAvailableArray } from "helpers";

const handlePending = (state) => {
  state.status = "pending";
  state.error = null;
};
const handleRejected = (state, action, status = "rejected") => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = "fulfilled") => {
  state.status = status;
  state.error = null;
};

const initialState = {};

export const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    resetFaqsState() { return initialState; },
    resetFaqsStatus(state) { state.status = ""; },
    resetFaqsError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createFaq.pending, (state, action) => { handlePending(state); })
      .addCase(createFaq.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(createFaq.fulfilled, (state, action) => { handleFulfilled(state, "added");
        const newList = [action.payload, ...isAvailableArray(state.list)];
        state.list = newList;
      })
      .addCase(getFaqs.pending, (state, action) => { handlePending(state); })
      .addCase(getFaqs.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getFaqs.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(updateFaqById.pending, (state, action) => { handlePending(state); })
      .addCase(updateFaqById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateFaqById.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        const newList = [...state.list].map((i) => {
          if (action.payload?.faq_id === i?.faq_id) return action.payload;
          return i;
        })?.sort((a, b) => a?.order - b?.order);

        state.list = newList;
      })
      .addCase(deleteFaqById.pending, (state, action) => { handlePending(state); })
      .addCase(deleteFaqById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(deleteFaqById.fulfilled, (state, action) => { handleFulfilled(state, "deleted");
        state.list = [...state.list].filter((i) => action.payload?.faq_id !== i?.faq_id);
      })
});

export const { resetFaqsState, resetFaqsStatus, resetFaqsError } = faqsSlice.actions;
export const faqsReducer = faqsSlice.reducer;
