import React from "react";
// styled
import { BurgerMenuBtnLine, BurgerMenuBtnTag } from "./BurgerMenu.styled";

const BurgerMenuBtn = ({ isBurgerMenuOpen, setIsBurgerMenuOpen, className = ""}) => {
  return (
    <BurgerMenuBtnTag
      className={ isBurgerMenuOpen ? `mobileMenuIsOpen ${className}` : `${className}` }
      onClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
      aria-label="Open mobile menu"
    >
      <BurgerMenuBtnLine />
      <BurgerMenuBtnLine />
      <BurgerMenuBtnLine />
    </BurgerMenuBtnTag>
  );
};

export default BurgerMenuBtn;
