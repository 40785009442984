import React from "react";
// components
import Logo from "components/Logo/Logo";
import LogoutBtn from "components/LogoutBtn/LogoutBtn";
import OfficeNav from "components/Nav/OfficeNav/OfficeNav";
import BurgerMenuBtn from "components/BurgerMenu/BurgerMenuBtn";
// styled
import { Aside, AsideBody, AsideHeader } from "./Sidebar.styled";

const Sidebar = ({ isBurgerMenuOpen, setIsBurgerMenuOpen, isSmallView }) => {
  return (
    <Aside className={(isBurgerMenuOpen || !isSmallView) && "active"}>
      <AsideHeader>
        <Logo className="header"/>
        <BurgerMenuBtn className="sidebar" isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />
      </AsideHeader>
      <AsideBody>
        <OfficeNav setIsBurgerMenuOpen={setIsBurgerMenuOpen} isSmallView={isSmallView} />
      </AsideBody>
      <LogoutBtn />
    </Aside>
  );
};

export default Sidebar;
