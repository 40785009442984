import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";

export const Aside = styled.aside`
  position: fixed;
  top: 0;
  left: 0;

  flex: 0 0 50px;
  display: flex;
  flex-direction: column;
  min-width: 50px;
  width: 50px;
  min-height: 100vh;
  height: 100%;

  transition: var(--transition--200);
  background-color: var(--color--dark, #0e0e12);
  border-right: 1px solid #f7fafc;
  box-shadow: 6px 6px 34px 0px rgba(0, 0, 0, 0.15);
  overflow-y: scroll;

  &.active { flex: 0 0 200px; width: 200px}

  #modal-root & {
    position: fixed;
    top: 0px;
    left: 100%;
    width: 100%;
    height: 100%;

    z-index: 3;
    transition: var(--transition--200);
    // background-color: var(--color--white, #fff);
    border: 0;
  }

  #modal-root &.active {left: 0;}
`;

export const AsideHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 12px;

  #modal-root & {
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;

    @media ${minDevice.tablet} {
      padding: 0 40px;
    }
  }

  @media ${minDevice.tablet} {
    height: 87px;
  }
`;
export const AsideBody = styled.div`
  flex-grow: 1;

  #modal-root & {
    width: 100%;
    margin: 0 auto;
    padding: 24px;

    max-width: 300px;
  }
`;
export const AsideFooter = styled.div``;