import styled from "styled-components";
// common
import { breakpoint, minDevice } from "common/breakpoints";
// styled
import { BtnAccentSolid } from "components/Button/Button.styled";

export const AuthFormTag = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  margin: 0 auto;

  background-color: #fff;
  border-radius: 26px;
  box-shadow: var(--box-shadow--main);

  @media ${minDevice.tablet} {
    width: ${breakpoint.mobile}px;
    padding: 32px;
  }
  @media ${minDevice.desktop} {
    padding: 48px 32px;
  }
`;
export const AuthLabel = styled.label`
  position: relative;
  flex-grow: 1;
`;
export const AuthInput = styled.input`
  width: 100%;
  height: 44px;
  padding: 8px 16px;

  color: var(--color--primary, #414141);
  font-size: 14px;
  line-height: 1;

  background-color: var(--color--bg, #F1F1F1);
  border-radius: 26px;
  border: 1px solid;
  border-color: transparent;

  &:hover {
    box-shadow: var(--box-shadow--main);
  }

  .disabled & {
    opacity: 0.5;
    pointer-events: none;
    cursor: auto;
  }
`;
export const AuthFormBtnSubmit = styled(BtnAccentSolid)`
  height: 48px;

  color: var(--color--dark, #0e0e12);
  font-weight: 700;
  font-size: 20px;

  border-radius: 26px;
`;

export const AuthFormEyeBtn = styled.button`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;

  border: none;
  background-color: transparent;

  & svg {
    width: 18px;
    height: 18px;
    opacity: 0.8;
    fill: var(--color--primary, #414141);
  }
`;
