export default function getLocalDateWithDayOfWeek(date, weekdayFormat = "short", res2 = false) {
  if (!date) return "";
  const newDate = new Date(date);

  const options = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  const resultDate = new Intl.DateTimeFormat("uk-UA", options).format(newDate);

  const en = new Intl.DateTimeFormat("en-US", { weekday: weekdayFormat}).format(newDate);
  // const cz = new Intl.DateTimeFormat("cs-CZ", { weekday: weekdayFormat}).format(newDate);

  const result = { 
    en: en.charAt(0).toUpperCase() + en.slice(1) + ", " + resultDate,
    // cz: cz.charAt(0).toUpperCase() + cz.slice(1) + ", " + resultDate,
  };

  const result2 = { 
    en: resultDate + ", " + en.charAt(0).toUpperCase() + en.slice(1),
    // cz: resultDate + ", " + cz.charAt(0).toUpperCase() + cz.slice(1),
  };

  if (res2) return result2;


  return result;
}
