import { useState, useEffect } from "react";
// common
import { BREAKPOINT_FOR_MOBILE_MENU, BREAKPOINT_FOR_MOBILE_MENU_OFFICE, } from "common/constants";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const isSmallView = width < BREAKPOINT_FOR_MOBILE_MENU;
  const isSmallViewOFFICE = width < BREAKPOINT_FOR_MOBILE_MENU_OFFICE;
  const is560 = width >= 560;
  const is768 = width >= 768;
  const is1024 = width >= 1024;
  const is1200 = width >= 1200;
  const is1440 = width >= 1440;
  const is1920 = width >= 1920;

  return { width, height, isSmallView, isSmallViewOFFICE, is560, is768, is1024, is1200, is1440, is1920 };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
