import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

axios.defaults.baseURL = 'https://api.crazzzyhall.com/api/';
// axios.defaults.baseURL = "http://localhost:3333/api/";

const authToken = {
  set(token) { axios.defaults.headers.common.Authorization = `Bearer ${token}`; },
  unset() { axios.defaults.headers.common.Authorization = ""; },
};

export const refreshUser = createAsyncThunk("auth/current", async (_, thunkAPI) => {
  const { token } = thunkAPI.getState().auth;

  if (!token) { return thunkAPI.rejectWithValue("No valid token"); }
  authToken.set(token);

  try {
    const res = await axios.get("/auth/current");
    return res.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const login = createAsyncThunk("auth/login", async (credentials, thunkAPI) => {
  try {
    const res = await axios.post("/auth/login", credentials);
    authToken.set(res.data.token);
    return res.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    await axios.post("/auth/logout");
    authToken.set();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const register = createAsyncThunk("auth/register", async (credentials, thunkAPI) => {
  try {
    const res = await axios.post("/auth/register", credentials);
    authToken.set(res.data.token);
    return res.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});
