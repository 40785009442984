import React from "react";
import { createPortal } from "react-dom";
// components
import Sidebar from "./Sidebar";

const modalRoot = document.querySelector("#modal-root");

const SidebarWrap = ({ isSmallView, isBurgerMenuOpen, setIsBurgerMenuOpen, }) => {
  return isSmallView ? (
    createPortal(
      <Sidebar
        isBurgerMenuOpen={isBurgerMenuOpen}
        setIsBurgerMenuOpen={setIsBurgerMenuOpen}
        isSmallView={isSmallView}
      />,
      modalRoot
    )
  ) : (
    <Sidebar
      isBurgerMenuOpen={isBurgerMenuOpen}
      setIsBurgerMenuOpen={setIsBurgerMenuOpen}
      isSmallView={isSmallView}
    />
  );
};

export default SidebarWrap;
