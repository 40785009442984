import styled from "styled-components";
import { NavLink } from "react-router-dom";
// common
import { breakpoint, maxDevice, minDevice } from "common/breakpoints";

export const Section = styled.section`
  width: 100%;
  padding: 16px 0;

  @media ${minDevice.tablet} {
    padding: 32px 0;
  }
`;
export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  // ===== extra breakpoints =====
  @media ${minDevice.mobile} {
    max-width: ${breakpoint.mobile}px;
  }
  // ===== extra breakpoints =====

  @media ${minDevice.tablet} {
    max-width: ${breakpoint.tablet}px;
    padding: 0 40px;
  }
  @media ${minDevice.desktopL} {
    max-width: ${breakpoint.desktopL}px;
    padding: 0 80px;
  }
  @media ${minDevice.desktopXL} {
    max-width: ${breakpoint.desktopXL}px;
    padding: 0 240px;
  }
`;
export const ContainerPrivate = styled.div`
  width: 100%;
  margin: 0 auto;
  --padding: 16px;
  padding: 0 var(--padding);

  @media ${minDevice.tablet} {
    --padding: 40px;
  }

  @media ${minDevice.desktopXL} {
    max-width: ${breakpoint.desktopXL}px;
  }
`;

export const PageTitle = styled.h1`
  color: var(--color--primary, #414141);
  font-family: "Helvetica Now Display";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  text-transform: uppercase;
  text-align: center;

  & span {
    color: var(--color--accent, #f19e1d);
  }

  @media ${minDevice.tablet} {
    line-height: 40px;
  }
  @media ${minDevice.desktopXL} {
    font-size: 48px;
    line-height: 56px;
  }
`;

export const SectionTitle = styled.h2`
  color: var(--color--primary, #414141);
  font-family: "Helvetica Now Display";
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;

  @media ${minDevice.tablet} {
    line-height: 40px;
  }
  @media ${minDevice.desktopXL} {
    font-size: 48px;
    line-height: 56px;
  }

  & span {
    color: var(--color--accent, #f19e1d);
  }
`;

export const SectionSubTitle = styled.h3`
  color: var(--color--primary, #414141);
  font-family: "Helvetica Now Display";
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;

  @media ${minDevice.tablet} {
    font-size: 32px;
    line-height: 40px;
  }
  @media ${minDevice.desktopXL} {
    font-size: 36px;
    line-height: 48px;
  }

  & span {
    color: var(--color--accent, #f19e1d);
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  padding: 20px 0;

  background-color: var(--color--bg, #f1f1f1);
  z-index: 1000;
  overflow: scroll;
  transform: scale(0);
  transition: 200ms linear;

  &.modalIsOpen { transform: scale(1); }
  &.datepicker {z-index: 1001}

  @media ${minDevice.desktopXL} {
    padding: 100px 0;
  }
`;
export const ModalContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
`;
export const ModalOfficeContainer = styled(ContainerPrivate)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
`;
export const ModaleBtnClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;

  color: var(--color--primary, #414141);

  background-color: transparent;
  border: none;
  cursor: pointer;

  & svg {
    width: 32px;
    height: 32px;
  }
`;

export const BasicNavLink = styled(NavLink)`
  color: currentColor;
  text-decoration: underline;
`;
export const AccentNavLink = styled(BasicNavLink)`
  color: var(--color--accent, #f19e1d);
`;
export const BasicLink = styled(NavLink)`
  color: currentColor;
  text-decoration: underline;
`;
export const AccentLink = styled(BasicNavLink)`
  color: var(--color--accent, #f19e1d);
`;

export const OfficePageHeaderContainer = styled(ContainerPrivate)`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin-top: 40px;

  @media ${maxDevice.tablet} {
    flex-direction: column;
  }
`;
export const OfficePageTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  min-height: 56px;
  margin-right: auto;
`;

export const OfficePageTitle = styled.h1`
  color: var( --color--accent, #9CFE01);
  font-size: 28px;
  font-weight: 700;
  line-height: 1;

  &.inaktiv { color: var(--color--gray, #848484); }
`;

export const OfficeBtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Status = styled.span`
  display: inline-block;
  height: 20px;
  padding: 4px 8px;

  font-size: 12px;
  line-height: 1;

  border-radius: 4px;
`;
