const { createSpecial } = require("./createSpecial");
const { getSpecials } = require("./getSpecials");
const { updateSpecialById } = require("./updateSpecialById");
const { deleteSpecialById } = require("./deleteSpecialById");

module.exports = {
  createSpecial,
  getSpecials,
  updateSpecialById,
  deleteSpecialById,
};
