import React from "react";
import { Navigate, Outlet, useLocation, useOutletContext, } from "react-router-dom";
// hooks
import useAuth from "hooks/useAuth";
// helpers
import { compareStrings } from "helpers";
// pages
import ErrorPermissionPage from "pages/ErrorPermissionPage/ErrorPermissionPage";
// const ErrorNotFoundPage = lazy(() => import("pages/ErrorNotFoundPage/ErrorNotFoundPage") );
// const ErrorPermissionPage = lazy(() => import("pages/ErrorPermissionPage/ErrorPermissionPage") );

// [ "superadmin", manager];

export const RestrictedRoute = ({ component: Component, redirectTo }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname;

  return isLoggedIn ? (
    <Navigate to={from || redirectTo} state={{ from: location }} />
  ) : (
    <Component />
  );
};
export const RestrictedRoute2 = ({ component: Component }) => {
  const { isLoggedIn, user } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname;
  
  let redirectTo = "/dashboard";
  if (compareStrings(user?.role, "admin")) redirectTo = "/services";
  if (compareStrings(user?.role, "rental manager")) redirectTo = "/bookings";
  if (compareStrings(user?.role, "sales manager")) redirectTo = "/sales";

  return isLoggedIn ? (
    <Navigate to={from || redirectTo} state={{ from: location }} />
  ) : (
    <Component />
  );
};

export const PrivateRoute = ({ component: Component, redirectTo }) => {
  const { isLoggedIn, isRefreshing } = useAuth();
  const shouldRedirect = !isRefreshing && !isLoggedIn;
  return shouldRedirect ? <Navigate to={redirectTo} /> : <Component />;
};
export const PermissionRoute = ({ component: Component, allowedRoles }) => {
  const { user } = useAuth();

  return user?.permissions?.find((permission) =>
    allowedRoles?.includes(permission)
  ) ? (
    <Component />
  ) : (
    <ErrorPermissionPage />
  );
};

export const PermissionRoutes = ({ allowedRoles }) => {
  const contextValue = useOutletContext();
  const { user } = useAuth();

  return user?.permissions?.find((permission) =>
    allowedRoles?.includes(permission)
  ) ? (
    <Outlet context={contextValue} />
  ) : (
    <ErrorPermissionPage />
  );
};

export const PrivateRoutes = ({ redirectTo }) => {
  const location = useLocation();
  const { isLoggedIn, isRefreshing } = useAuth();
  const shouldRedirect = !isRefreshing && !isLoggedIn;

  return shouldRedirect ? (
    <Navigate to={redirectTo} replace state={{ from: location }} />
  ) : (
    <Outlet />
  );
};
