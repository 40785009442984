import { createSlice } from "@reduxjs/toolkit";
import { createSpecial, deleteSpecialById, getSpecials, updateSpecialById } from "./operations";
import { isAvailableArray } from "helpers";

const handlePending = (state) => {
  state.status = "pending";
  state.error = null;
};
const handleRejected = (state, action, status = "rejected") => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = "fulfilled") => {
  state.status = status;
  state.error = null;
};

const initialState = {};

export const specialsSlice = createSlice({
  name: "specials",
  initialState,
  reducers: {
    resetSpecialsState() { return initialState; },
    resetSpecialsStatus(state) { state.status = ""; },
    resetSpecialsError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createSpecial.pending, (state, action) => { handlePending(state); })
      .addCase(createSpecial.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(createSpecial.fulfilled, (state, action) => { handleFulfilled(state, "added");
        const newList = [action.payload, ...isAvailableArray(state.list)];
        state.list = newList;
      })
      .addCase(getSpecials.pending, (state, action) => { handlePending(state); })
      .addCase(getSpecials.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getSpecials.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(updateSpecialById.pending, (state, action) => { handlePending(state); })
      .addCase(updateSpecialById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateSpecialById.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        const newList = [...state.list].map((i) => {
          if (action.payload?.special_id === i?.special_id) return action.payload;
          return i;
        })?.sort((a, b) => a?.order - b?.order);

        state.list = newList;
      })
      .addCase(deleteSpecialById.pending, (state, action) => { handlePending(state); })
      .addCase(deleteSpecialById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(deleteSpecialById.fulfilled, (state, action) => { handleFulfilled(state, "deleted");
        state.list = [...state.list].filter((i) => action.payload?.special_id !== i?.special_id);
      })
});

export const { resetSpecialsState, resetSpecialsStatus, resetSpecialsError } = specialsSlice.actions;
export const specialsReducer = specialsSlice.reducer;
