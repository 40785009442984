import React from "react";
import { LogoLink } from "./Logo.styled";

const Logo = ({ className }) => {
  return (
    <LogoLink className={className} to="/specials" aria-label="Go to homepage">
      <svg className="logo__icon logo__icon--0" xmlns="http://www.w3.org/2000/svg" width="98" height="45" viewBox="0 0 98 45" fill="none">
        <path d="M39.2685 15.8736H29.9361C29.7656 14.6662 29.4176 13.5938 28.892 12.6562C28.3665 11.7045 27.6918 10.8949 26.8679 10.2273C26.044 9.55966 25.0923 9.04829 24.0128 8.69318C22.9474 8.33807 21.7898 8.16051 20.5398 8.16051C18.2813 8.16051 16.3139 8.72159 14.6378 9.84375C12.9616 10.9517 11.6619 12.571 10.7386 14.7017C9.81534 16.8182 9.35369 19.3892 9.35369 22.4148C9.35369 25.5256 9.81534 28.1392 10.7386 30.2557C11.6761 32.3722 12.983 33.9702 14.6591 35.0497C16.3352 36.1293 18.2741 36.669 20.4759 36.669C21.7116 36.669 22.8551 36.5057 23.9063 36.179C24.9716 35.8523 25.9162 35.3764 26.7401 34.7514C27.5639 34.1122 28.2457 33.3381 28.7855 32.429C29.3395 31.5199 29.723 30.483 29.9361 29.3182L39.2685 29.3608C39.027 31.3636 38.4233 33.2955 37.4574 35.1562C36.5057 37.0028 35.2202 38.6577 33.6009 40.1207C31.9957 41.5696 30.0781 42.7202 27.848 43.5724C25.6321 44.4105 23.125 44.8295 20.3267 44.8295C16.4347 44.8295 12.9545 43.9489 9.88636 42.1875C6.83239 40.4261 4.41761 37.8764 2.64205 34.5384C0.880682 31.2003 0 27.1591 0 22.4148C0 17.6563 0.894886 13.608 2.68466 10.2699C4.47443 6.93182 6.90341 4.3892 9.97159 2.64205C13.0398 0.880682 16.4915 0 20.3267 0C22.8551 0 25.1989 0.355114 27.358 1.06534C29.5312 1.77557 31.456 2.8125 33.1321 4.17614C34.8082 5.52557 36.1719 7.1804 37.223 9.14062C38.2884 11.1009 38.9702 13.3452 39.2685 15.8736Z" fill="#fff"/>
        <path d="M45.7138 44.233V11.5057H54.5135V17.2159H54.8544C55.451 15.1847 56.4524 13.6506 57.8587 12.6136C59.2649 11.5625 60.8842 11.0369 62.7166 11.0369C63.1712 11.0369 63.6612 11.0653 64.1868 11.1222C64.7124 11.179 65.174 11.2571 65.5717 11.3565V19.4105C65.1456 19.2827 64.5561 19.169 63.8033 19.0696C63.0504 18.9702 62.3615 18.9205 61.7365 18.9205C60.4013 18.9205 59.2081 19.2116 58.157 19.794C57.12 20.3622 56.2962 21.1577 55.6854 22.1804C55.0888 23.2031 54.7905 24.3821 54.7905 25.7173V44.233H45.7138Z" fill="#fff"/>
        <path d="M78.6594 44.8509C76.5714 44.8509 74.7106 44.4886 73.0771 43.7642C71.4435 43.0256 70.1509 41.9389 69.1992 40.5043C68.2617 39.0554 67.793 37.2514 67.793 35.0923C67.793 33.2742 68.1268 31.7472 68.7944 30.5114C69.462 29.2756 70.3711 28.2813 71.5217 27.5284C72.6722 26.7756 73.979 26.2074 75.4421 25.8239C76.9194 25.4403 78.4677 25.1705 80.087 25.0142C81.9904 24.8153 83.5245 24.6307 84.6893 24.4602C85.854 24.2756 86.6992 24.0057 87.2248 23.6506C87.7504 23.2955 88.0131 22.7699 88.0131 22.0739V21.946C88.0131 20.5966 87.587 19.5526 86.7347 18.8139C85.8967 18.0753 84.7035 17.706 83.1552 17.706C81.5217 17.706 80.2219 18.0682 79.256 18.7926C78.2901 19.5028 77.6509 20.3977 77.3384 21.4773L68.9435 20.7955C69.3697 18.8068 70.2077 17.0881 71.4577 15.6392C72.7077 14.1761 74.32 13.054 76.2944 12.2727C78.283 11.4773 80.5842 11.0795 83.1978 11.0795C85.016 11.0795 86.756 11.2926 88.418 11.7188C90.0941 12.1449 91.5785 12.8054 92.8711 13.7003C94.1779 14.5952 95.2077 15.7457 95.9606 17.152C96.7134 18.544 97.0898 20.2131 97.0898 22.1591V44.233H88.4819V39.6946H88.2262C87.7006 40.7173 86.9975 41.6193 86.1168 42.4006C85.2362 43.1676 84.1779 43.7713 82.9421 44.2117C81.7063 44.6378 80.2788 44.8509 78.6594 44.8509ZM81.2589 38.5867C82.5941 38.5867 83.7731 38.3239 84.7958 37.7983C85.8185 37.2585 86.6211 36.5341 87.2035 35.625C87.7859 34.7159 88.0771 33.6861 88.0771 32.5355V29.0625C87.793 29.2472 87.4024 29.4176 86.9052 29.5739C86.4222 29.7159 85.8754 29.8509 85.2646 29.9787C84.6538 30.0923 84.043 30.1989 83.4322 30.2983C82.8214 30.3835 82.2674 30.4616 81.7702 30.5327C80.7049 30.6889 79.7745 30.9375 78.979 31.2784C78.1836 31.6193 77.5657 32.081 77.1254 32.6634C76.685 33.2315 76.4648 33.9418 76.4648 34.794C76.4648 36.0298 76.9123 36.9744 77.8072 37.6278C78.7163 38.267 79.8668 38.5867 81.2589 38.5867Z" fill="#fff"/>
      </svg>
      <svg className="logo__icon logo__icon--1" xmlns="http://www.w3.org/2000/svg" width="28" height="46" viewBox="0 0 28 46" fill="none">
        <path d="M0 45.233V39.821L16.0227 19.9844V19.75H0.55397V0L26.9531 12.5057V18.4077L11.9105 37.7543V37.9886H27.5071V45.233H0Z" fill="#9CFE01"/>
      </svg>
      <svg className="logo__icon logo__icon--2" xmlns="http://www.w3.org/2000/svg" width="28" height="46" viewBox="0 0 28 46" fill="none">
        <path d="M0 45.233V39.821L16.0227 19.9844V19.75H0.55397V0L26.9531 12.5057V18.4077L11.9105 37.7543V37.9886H27.5071V45.233H0Z" fill="#9CFE01"/>
      </svg>
      <svg className="logo__icon logo__icon--3" xmlns="http://www.w3.org/2000/svg" width="28" height="46" viewBox="0 0 28 46" fill="none">
        <path d="M0 45.233V39.821L16.0227 19.9844V19.75H0.55397V0L26.9531 12.5057V18.4077L11.9105 37.7543V37.9886H27.5071V45.233H0Z" fill="#9CFE01"/>
      </svg>
      <svg className="logo__icon logo__icon--4" xmlns="http://www.w3.org/2000/svg" width="157" height="56" viewBox="0 0 157 56" fill="none">
        <path d="M8.20312 55.9091C7.05256 55.9091 5.97302 55.8168 4.96449 55.6321C3.97017 55.4617 3.1463 55.2415 2.49289 54.9716L4.53835 48.196C5.60369 48.5227 6.56249 48.7003 7.41476 48.7287C8.28124 48.7571 9.02699 48.5582 9.65199 48.1321C10.2912 47.706 10.8097 46.9815 11.2074 45.9588L11.7401 44.5739L0 10.9091H9.54546L16.321 34.9432H16.6619L23.5014 10.9091H33.1108L20.3906 47.1733C19.7798 48.9347 18.9489 50.4688 17.8977 51.7756C16.8608 53.0966 15.5469 54.1122 13.956 54.8224C12.3651 55.5469 10.4474 55.9091 8.20312 55.9091Z" fill="#fff"/>
        <path d="M51.8129 43.6364V0H61.0387V18.0043H79.7674V0H88.972V43.6364H79.7674V25.6108H61.0387V43.6364H51.8129Z" fill="#fff"/>
        <path d="M105.74 44.2543C103.652 44.2543 101.792 43.892 100.158 43.1676C98.5245 42.429 97.2319 41.3423 96.2802 39.9077C95.3427 38.4588 94.8739 36.6548 94.8739 34.4957C94.8739 32.6776 95.2078 31.1506 95.8754 29.9148C96.543 28.679 97.4521 27.6847 98.6026 26.9318C99.7532 26.179 101.06 25.6108 102.523 25.2273C104 24.8438 105.549 24.5739 107.168 24.4176C109.071 24.2188 110.605 24.0341 111.77 23.8636C112.935 23.679 113.78 23.4091 114.306 23.054C114.831 22.6989 115.094 22.1733 115.094 21.4773V21.3494C115.094 20 114.668 18.956 113.816 18.2173C112.978 17.4787 111.784 17.1094 110.236 17.1094C108.603 17.1094 107.303 17.4716 106.337 18.196C105.371 18.9063 104.732 19.8011 104.419 20.8807L96.0245 20.1989C96.4506 18.2102 97.2887 16.4915 98.5387 15.0426C99.7887 13.5795 101.401 12.4574 103.375 11.6761C105.364 10.8807 107.665 10.483 110.279 10.483C112.097 10.483 113.837 10.696 115.499 11.1222C117.175 11.5483 118.659 12.2088 119.952 13.1037C121.259 13.9986 122.289 15.1491 123.042 16.5554C123.794 17.9474 124.171 19.6165 124.171 21.5625V43.6364H115.563V39.098H115.307C114.782 40.1207 114.078 41.0227 113.198 41.804C112.317 42.571 111.259 43.1747 110.023 43.6151C108.787 44.0412 107.36 44.2543 105.74 44.2543ZM108.34 37.9901C109.675 37.9901 110.854 37.7273 111.877 37.2017C112.899 36.6619 113.702 35.9375 114.284 35.0284C114.867 34.1193 115.158 33.0895 115.158 31.9389V28.4659C114.874 28.6506 114.483 28.821 113.986 28.9773C113.503 29.1193 112.956 29.2543 112.346 29.3821C111.735 29.4957 111.124 29.6023 110.513 29.7017C109.902 29.7869 109.348 29.8651 108.851 29.9361C107.786 30.0923 106.855 30.3409 106.06 30.6818C105.265 31.0227 104.647 31.4844 104.206 32.0668C103.766 32.6349 103.546 33.3452 103.546 34.1974C103.546 35.4332 103.993 36.3778 104.888 37.0312C105.797 37.6705 106.948 37.9901 108.34 37.9901Z" fill="#fff"/>
        <path d="M140.289 0V43.6364H131.213V0H140.289Z" fill="#fff"/>
        <path d="M156.637 0V43.6364H147.56V0H156.637Z" fill="#fff"/>
      </svg>
    </LogoLink>
  );
};

export default Logo;
