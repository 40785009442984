import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
// redux
import { selectUser, selectIsLoggedIn, selectIsRefreshing, selectIsLoggedOut, selectToken } from 'redux/auth/authSelectors';
import { compareStrings } from 'helpers';

const useAuth = () => {
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  let userInfo = user;
  let isSuperAdmin = false;
  let isAdmin = false;
  let isSalesManager = false;
  let isRentalManager = false;

  if (token) {
    const decoded = jwtDecode(token);
    const { role, permissions } = decoded?.userInfo;
    userInfo = {...user, role, permissions}
    isSuperAdmin = compareStrings(role, "superadmin");
    isAdmin = compareStrings(role, "admin");
    isRentalManager = compareStrings(role, "rental manager");
    isSalesManager = compareStrings(role, "sales manager");
  }

  let authState = {
    user: userInfo,
    isLoggedIn: useSelector(selectIsLoggedIn),
    isLoggedOut: useSelector(selectIsLoggedOut),
    isRefreshing: useSelector(selectIsRefreshing),
    isSuperAdmin,
    isAdmin,
    isSalesManager,
    isRentalManager,
    isAdmins: isSuperAdmin || isAdmin,
    isManagers: isRentalManager || isSalesManager,
  }

  return authState
};

export default useAuth;