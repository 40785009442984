const { createFaq } = require("./createFaq");
const { getFaqs } = require("./getFaqs");
const { updateFaqById } = require("./updateFaqById");
const { deleteFaqById } = require("./deleteFaqById");

module.exports = {
  createFaq,
  getFaqs,
  updateFaqById,
  deleteFaqById,
};
