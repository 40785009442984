import React from "react";
import { useSelector } from "react-redux";
// redux
import { selectAuthError } from "redux/auth/authSelectors";
// components
import AuthForm from "components/forms/AuthForm/AuthForm";
// styled
import { Container, Section } from "components/Common/common.styled";
import Logo from "components/Logo/Logo";

const AuthPage = () => {
  const authError = useSelector(selectAuthError);

  return (
    <>
      <Section>
        <Container>
          <div style={{ marginBottom: "40px"}}>
            <Logo />
          </div>
          <AuthForm />
          {authError && ( <p style={{ margin: "20px auto", maxWidth: "480px", textAlign: "center", }} > Wrong login or password </p> )}
        </Container>
      </Section>
    </>
  );
};

export default AuthPage;
