import React, { lazy, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// hooks
import { useAuth, useWindowDimensions } from "hooks";
import { PermissionRoute, PrivateRoutes, RestrictedRoute, } from "hooks/useRoute";
// layouts
import { PrivateLayout } from "layouts";
// pages
import AuthPage from "pages/AuthPage/AuthPage";
// components
import Loader from "components/Loader/Loader";
import { useDispatch } from "react-redux";
import { refreshUser } from "redux/auth/authOperation";
// ===== ADMIN Pages =====
const SpecialsPage = lazy(() => import("pages/SpecialsPage/SpecialsPage"));
const NewsPage = lazy(() => import("pages/NewsPage/NewsPage"));
const FAQPage = lazy(() => import("pages/FAQPage/FAQPage"));

const App = () => {
  const [render, setRender] = useState(1);
  const dispatch = useDispatch();
  const location = useLocation();
  const { isRefreshing } = useAuth();
  const { height } = useWindowDimensions();
  const allowedRolesAdmins = ["superadmin", "admin"];

  useEffect(() => {
    if (render) return setRender(0);
    dispatch(refreshUser());
  }, [dispatch, render, setRender]);

  useEffect(() => { window.scrollTo({ top: 0, behavior: "smooth" }); }, [location]);
  useEffect(() => { document.getElementById("root").style.minHeight = height + "px"; }, [height]);

  return isRefreshing ? (
    <Loader />
  ) : (
    <Routes>
      <Route path="/login" element={ <RestrictedRoute component={AuthPage} redirectTo="/specials" /> }  />

      <Route path="/" element={<PrivateRoutes redirectTo="/login" />}>
        <Route element={ <PermissionRoute component={PrivateLayout} allowedRoles={allowedRolesAdmins} /> } >
          <Route index element={<SpecialsPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/faq" element={<FAQPage />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
