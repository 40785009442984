import React, { useState } from "react";
import { useDispatch } from "react-redux";
// redux
import { login } from "redux/auth/authOperation";
// components
import SvgUse from "components/SvgUse/SvgUse";
// styled
import { AuthFormTag, AuthLabel, AuthInput, AuthFormEyeBtn, AuthFormBtnSubmit, } from "./AuthForm.styled";

const AuthForm = () => {
  const [passwordIsHidden, setPasswordIsHidden] = useState(true);

  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    dispatch(
      login({
        email: form.elements.email.value?.trim(),
        password: form.elements.password.value?.trim(),
      })
    );
    form.reset();
  };

  return (
    <AuthFormTag onSubmit={handleSubmit} id="authForm">
      <AuthLabel>
        <AuthInput type="email" name="email" placeholder="Email" autoComplete="off" required={true} />
      </AuthLabel>
      <AuthLabel>
        <AuthInput type={passwordIsHidden ? "password" : "text"} name="password" placeholder='Password' autoComplete="off" required={true} />
        <AuthFormEyeBtn type="button" onClick={() => setPasswordIsHidden(!passwordIsHidden)}>
          <SvgUse icontag={passwordIsHidden ? "eye" : "eye-blocked"} />
        </AuthFormEyeBtn>
      </AuthLabel>

      <AuthFormBtnSubmit type="submit"> Login </AuthFormBtnSubmit>
    </AuthFormTag>
  );
};

export default AuthForm;
