import styled from "styled-components";

export const FlexAcJc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
// ===== div =====
export const CardSet = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
`;
export const CardSetItem = styled.div`
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
`;
export const CardSetPlusItem = styled(CardSet)`
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
`;

// ===== label =====
export const CardSetItemLabel = styled.label`
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
`;

// ===== ul & li =====
export const CardSetUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
`;

export const CardSetItemLi = styled.li`
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
`;