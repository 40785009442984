import styled from "styled-components";
// common
import { minDevice } from "common/breakpoints";
// styled
import { ContainerPrivate } from "components/Common/common.styled";

export const HeaderPrivateTag = styled.header`
  position: fixed;
  z-index: 3;

  flex: 0 0 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 12px 0;

  background-color: var(--color--dark, #0e0e12); 
  // color: var(--color--primary, #414141);
  // background-color: var(--color--white, #fff);
  // box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.15);

  @media ${minDevice.tablet} {
    height: 87px;
  }
  @media ${minDevice.desktop} {
    width: calc(100% - 200px);
  }
`;

export const HeaderPrivateContainer = styled(ContainerPrivate)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;
