import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import { langReducer } from "./lang/langSlice";
import { authReducer } from "./auth/authSlice";
import { specialsReducer } from "./specials/specialsSlice";
import { newsReducer } from "./news/newsSlice";
import { faqsReducer } from "./faqs/faqsSlice";

// import { textsReducer } from "./texts/textsSlice";

const langPersistConfig = {
  key: "lang",
  storage,
  whitelist: ["lang"],
};
const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["token"],
};

export const store = configureStore({
  reducer: {
    lang: persistReducer(langPersistConfig, langReducer),
    auth: persistReducer(authPersistConfig, authReducer),
    specials: specialsReducer,
    news: newsReducer,
    faqs: faqsReducer,

    // texts: textsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
