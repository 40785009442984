import { createSlice } from "@reduxjs/toolkit";
import { createNews, getNews , updateNewsById, deleteNewsById } from "./operations";
import { isAvailableArray } from "helpers";

const handlePending = (state) => {
  state.status = "pending";
  state.error = null;
};
const handleRejected = (state, action, status = "rejected") => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = "fulfilled") => {
  state.status = status;
  state.error = null;
};

const initialState = {};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    resetNewsState() { return initialState; },
    resetNewsStatus(state) { state.status = ""; },
    resetNewsError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createNews.pending, (state, action) => { handlePending(state); })
      .addCase(createNews.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(createNews.fulfilled, (state, action) => { handleFulfilled(state, "added");
        const newList = [action.payload, ...isAvailableArray(state.list)];
        state.list = newList;
      })
      .addCase(getNews.pending, (state, action) => { handlePending(state); })
      .addCase(getNews.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getNews.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(updateNewsById.pending, (state, action) => { handlePending(state); })
      .addCase(updateNewsById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(updateNewsById.fulfilled, (state, action) => { handleFulfilled(state, "updated");
        const newList = [...state.list].map((i) => {
          if (action.payload?.news_id === i?.news_id) return action.payload;
          return i;
        })?.sort((a, b) => a?.order - b?.order);

        state.list = newList;
      })
      .addCase(deleteNewsById.pending, (state, action) => { handlePending(state); })
      .addCase(deleteNewsById.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(deleteNewsById.fulfilled, (state, action) => { handleFulfilled(state, "deleted");
        state.list = [...state.list].filter((i) => action.payload?.news_id !== i?.news_id);
      })
});

export const { resetNewsState, resetNewsStatus, resetNewsError } = newsSlice.actions;
export const newsReducer = newsSlice.reducer;
